import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { RichText } from "prismic-reactjs"

import Layout from "~/components/layout"
import Container from "~/components/ui/container"
import SEO from "~/components/seo"

import * as styles from "./index.module.scss"

interface Props {
  pageContext: any
}

const NewsTemplate: React.FC<Props> = ({ pageContext: { data } }) => {
  const [isAnimated, setIsAnimated] = React.useState(false)

  React.useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])

  return (
    <Layout>
      <SEO
        title={data.title.text}
        meta={[
          {
            property: `og:image`,
            content: `https://www.urus.org${data.cover.localFile.publicURL}`,
          },
          {
            name: `twitter:image`,
            content: `https://www.urus.org${data.cover.localFile.publicURL}`,
          },
        ]}
      />
      <article className={styles.root}>
        <div
          className={classNames(styles.animation, {
            [styles.animation__animated]: isAnimated,
          })}
        ></div>

        <Container>
          <h1 className={classNames("headline--decorated", styles.headline)}>
            Urus <span>News</span>
          </h1>
        </Container>

        <div className={styles.title__wrapper}>
          <Container>
            <h2 className={styles.title}>{data.title.text}</h2>
          </Container>
        </div>

        <Container>
          <div className={styles.content}>
            <div className={styles.content__cover}>
              <GatsbyImage
                alt={data.cover.alt || ""}
                image={data.cover.localFile.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>

            <div className={styles.content__text}>
              <RichText render={data.text.raw} />
            </div>

            <div className={styles.content__about}>
              <div className={styles.about__stripe} />
              <h3 className={styles.about__headline}>About Urus</h3>
              <p className={styles.about}>
                Formed in 2018, URUS (www.urus.org) is a holding company with
                cooperative and private ownership. Urus has strong roots in the
                global agriculture industry. This deep history is anchored by
                the companies that compose URUS: AgSource, Alta Genetics, GENEX,
                Jetstream Genetics, PEAK/GENESIS, SCCL and Valley Ag Software.
                It’s also fueled by a worldwide team of professionals dedicated
                to providing dairy and beef producers with genetic and farm
                management information solutions that improve herd quality and
                productivity. 
              </p>
            </div>
          </div>
        </Container>
      </article>
    </Layout>
  )
}

export default NewsTemplate
